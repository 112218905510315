const global = {
    area: "吉利华阳产业集聚区",
    objectName: "",
    //url
    // hostUrl : 'http://cuiyun.dexian.ren/',
    apiHttpsUrl: 'https://jlhyapi.dexian.ren/',
    apiRequestUrl: 'https://jlhyapi.dexian.ren/api',
    apiUploadUrl: 'https://jlhyapi.dexian.ren/api/UploadFile/UploadImgs',
    apiUploadFiles: 'https://jlhyapi.dexian.ren/api/UploadFile/UploadFiles',
    apiUploadvideo: 'https://jlhyapi.dexian.ren/api/UploadFile/UploadQiniuVideo',
}

export default global;